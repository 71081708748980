import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Button, 
    Typography, 
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup
} from '@material-ui/core';
import { getCsrfToken } from '../Api/TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStylesMarkQuestionError = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: '20px',
            width: '450px',
            fontFamily: 'Fira Sans, sans-serif',
        }
    },
    content: {
        padding: '20px 24px',
    },
    actions: {
        padding: '16px 24px',
        justifyContent: 'center',
        gap: '40px',
    },
    dialogTitle: {
        '& .MuiTypography-root': {
            fontFamily: 'Fira Sans, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            color: '#333',
            paddingBottom: 0,
        }
    },
    markButton: {
        backgroundColor: '#D32F2F',
        color: 'white',
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '8px 24px',
        minWidth: '120px',
        '&:hover': {
            backgroundColor: '#B71C1C',
        },
        '&.Mui-disabled': {
            backgroundColor: 'rgba(211, 47, 47, 0.5)',
            color: 'white',
        }
    },
    cancelButton: {
        backgroundColor: '#6BBDBD',
        color: 'white',
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '8px 24px',
        minWidth: '120px',
        '&:hover': {
            backgroundColor: '#5AACAC',
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    textField: {
        marginTop: theme.spacing(2),
        width: '100%',
        '& .MuiInputBase-root': {
            fontFamily: 'Fira Sans, sans-serif',
            fontSize: '14px',
        },
        '& .MuiFormHelperText-root': {
            fontFamily: 'Fira Sans, sans-serif',
            marginLeft: 0,
            fontSize: '12px',
        }
    },
    checkboxLabel: {
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: '14px',
        marginBottom: theme.spacing(0.5),
        color: '#333',
        '& .MuiTypography-root': {
            fontFamily: 'Fira Sans, sans-serif',
            fontSize: '14px',
        }
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
        '&.Mui-checked': {
            color: '#D32F2F',
        },
        padding: '6px 9px',
    },
    reasonTitle: {
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        marginBottom: theme.spacing(1.5),
        color: '#333',
    },
    description: {
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: '14px',
        color: '#333',
        marginBottom: theme.spacing(2),
    }
}));

const errorOptions = [
    { value: 'incorrect_answer', label: 'Respuesta incorrecta' },
    { value: 'unclear_question', label: 'Pregunta mal formulada' },
    { value: 'opciones', label: 'Opciones de respuesta poco claras' },
    { value: 'other', label: 'Otro motivo' }
];

const Flag = ({ open, onClose, questionId, accessToken, user, setIsFlagged, handleTrueFlag }) => {
    const classes = useStylesMarkQuestionError();
    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [otherReasonError, setOtherReasonError] = useState(false);

    const handleCheckboxChange = (value) => {
        setSelectedReason(value === selectedReason ? '' : value);
        if (value !== 'other') {
            setOtherReason('');
            setOtherReasonError(false);
        }
    };

    const handleOtherReasonChange = (e) => {
        const value = e.target.value;
        setOtherReason(value);
        setOtherReasonError(!value.trim());
    };

    const markQuestionAsErroneous = async () => {
        try {
            setIsFlagged(true);
            const user_id = user['sub'];
            const apiUrl = `${BACKEND_URL}/api_v1/question-info/${questionId}/?user_id=${user_id}`;

            const csrfToken = getCsrfToken();
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            };

            if (csrfToken) {
                headers['X-CSRFToken'] = csrfToken;
            }

            const errorReason = selectedReason === 'other' ? otherReason : 
                errorOptions.find(opt => opt.value === selectedReason)?.label;

            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify({
                    error_reason: errorReason
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            handleTrueFlag();
        } catch (error) {
            console.error('Error marking question as erroneous:', error);
        }
        onClose();
    };

    const handleNo = () => {
        setIsFlagged(false);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: classes.dialog,
                paper: classes.content,
            }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Pregunta Errónea
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.description}>
                    ¿Quieres marcar la pregunta como <strong>errónea</strong>? Si lo haces no aparecerá en <strong>futuros exámenes</strong>.
                </Typography>
                <FormControl component="fieldset" className={classes.formControl}>
                    <Typography className={classes.reasonTitle}>
                        Selecciona el motivo
                    </Typography>
                    <FormGroup>
                        {errorOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={selectedReason === option.value}
                                        onChange={() => handleCheckboxChange(option.value)}
                                        className={classes.checkbox}
                                    />
                                }
                                label={option.label}
                                className={classes.checkboxLabel}
                            />
                        ))}
                    </FormGroup>
                    {selectedReason === 'other' && (
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            size="small"
                            placeholder="Especifica el motivo"
                            value={otherReason}
                            onChange={handleOtherReasonChange}
                            error={otherReasonError}
                            helperText={otherReasonError ? "Este campo es obligatorio" : ""}
                            required
                            onBlur={() => setOtherReasonError(!otherReason.trim())}
                        />
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button 
                    onClick={markQuestionAsErroneous} 
                    className={classes.markButton}
                    disabled={!selectedReason || (selectedReason === 'other' && !otherReason)}
                >
                    Sí, marcar
                </Button>
                <Button onClick={handleNo} className={classes.cancelButton}>
                    No, seguir con el examen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Flag;
