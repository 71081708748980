import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Paper, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import UploadFilesWelcome from '../Welcome/UploadFileWelcome';
import Questionnaire from './Questionnaire';
import './style.css'; // Add this import at the top of the file
import '../Main/style.css'; // Add this import at the top of the file
import * as handle from "../AllModalsActions/handleModal";
import { useAuth } from '../Auth/Authhandle';
import { fetchUserFiles } from '../Api/FilesApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSubscription } from '../Context/SubscriptionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    [theme.breakpoints.down('700')]: {
      display: "flex",
    },
  },
  icon: {
    fontSize: theme.typography.h3.fontSize,
  },
  fullScreenBanner: {
    [theme.breakpoints.up('700')]: {
      display: "none"
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: "white",
    textAlign: 'center',
    position: 'relative',
  },
  bannerImage: {
    maxWidth: '100%',
    maxHeight: '70%',
  },
  bannerText: {
    color: "#026277",
    fontSize: "20px",
    marginTop: "20px",
    fontWeight: 900,
    lineHeight: "120%",
    color: "#6BBDBD",
    margin: "20px"
  },
  title: {
    fontWeight: 'bold',
    fontSize: '22px', // 
    fontFamily: 'Inter',
    marginTop: "50px",
    marginBottom: "5px", // 10px to rem
    color: '#6BBDBD',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: '16px', // 16px to rem
    fontWeight: '400',
    color: '#666666',
  },
  stepTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '0.3125rem', // 5px to rem
  },
  stepDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    color: '#666666',
    maxWidth: '80%', // Increased from 50% to 80%
    margin: '0 auto',
  },
  statsTitle: {
    color: '#6BBDBD',
    marginBottom: theme.spacing(1),
  },
  statsNumber: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 'bold',
    fontSize: '1.25rem', // 20px to rem
    color: 'white',
  },
  statsText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1rem', // 16px to rem
    color: 'white',
  },
  statsSmallText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem', // 14px to rem
    color: 'white',
  },
  uploadTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem', // 24px to rem
    fontFamily: 'Inter',
  },
  uploadSubtitle: {
    fontFamily: 'Inter',
    fontSize: '1rem', // 16px to rem
    fontWeight: '400',
    color: '#666666',
  },
  stepContainer: {
    marginBottom: theme.spacing(3),
  },
  statsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  statsGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    height: '100vh',
    [theme.breakpoints.down('700')]: {
      display: 'none',
    },
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
}));

const NewOnboarding = () => {
  const [files, setFiles] = useState([]);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const { user, accessToken } = useAuth();
  const { userData } = useSubscription();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  useEffect(() => {

    if (userData.done_onboarding) {
      navigate("/")
    }
  }, [userData, location]);
  const steps = [
    {
      icon: <InsertDriveFileIcon fontSize="small" />,
      title: 'Sube un documento a estudiar',
      description: 'Puedes subir cualquier PDF, incluso escaneados',
      color: '#FFBF60',
    },
    {
      icon: <AccessTimeIcon fontSize="small" />,
      title: 'Deja que Typed prepare el material',
      description: 'Elaboraremos preguntas tipo test y resúmenes basados en tu documento',
      color: '#5243C2',
    },
    {
      icon: <EditIcon fontSize="small" />,
      title: '¡Empieza a estudiar!',
      description: 'Empieza a responder preguntas, estudiar tu resumen o resolver tus dudas',
      color: '#6BBDBD',
    },
  ];

  // Add this function to check if files are empty
  const isFilesEmpty = () => {
    return files.length === 0;
  };
  const handleUploadSkip = async () => {
    setShowQuestionnaire(true);

  }
  // Add this new function
  const handleUpload = async () => {
    setShowQuestionnaire(true);

    const formData = new FormData();
    let filesToUpload;
    if (files && files.length > 0) {

      filesToUpload = files.map((fileItem) => fileItem.file); // Extracting File objects
      // setMessage("El archivo se está procesando. Te avisaremos cuando esté");

      // Function to load metadata for a single file and append to FormData
      const loadMetadata = (file, index) => {
        return new Promise((resolve) => {
          formData.append(`file_${index}`, file);
          formData.append(`file_${index}_size`, file.size);
          formData.append(`file_${index}_duration`, 0);

          resolve();
          // }
        });
      };


      // Wait for all files to have their metadata loaded
      const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
      await Promise.all(metadataPromises);
    }
    fetchUserFiles(accessToken, "POST", "Nueva carpeta", formData, user)

    // You can add more logic here to process the files
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://widget.senja.io/widget/3bd958a6-7beb-4d50-acc4-6e5730b43bd9/platform.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* <div className={classes.fullScreenBanner}>
        <div>

          <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/iconName.svg" alt="Typed Icon" className={classes.typedIconImg} />
        </div>
        <Typography className={classes.bannerText}>
          Typed solo está disponible en ordenadores

        </Typography>
        <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/PhoneImage.svg" alt="Typed only available on computers" className={classes.bannerImage} />
      </div> */}

      {isMobile ? (
        <Grid container className={classes.root}>
          <Grid item xs={12} style={{
            overflowY: 'auto',
            height: '100vh',
          }}>
            <Box p={2} bgcolor="#F8FFFF" height="100%" display="flex" flexDirection="column" alignItems="center">
              {/* Upload Paper or Questionnaire */}
              <Box sx={{ textAlign: 'center', mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "60px" }}>
                <Typography variant="h5" className={classes.uploadTitle}>
                  Sube tu primer documento
                </Typography>
                <Typography variant="h5" style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", color: "#666666", marginTop: "10px", width: "80%" }}>
                  Genera preguntas test y resúmenes completos de cualquier documento con nuestra IA
                </Typography>
              </Box>

              {showQuestionnaire ? (
                <Paper style={{
                  padding: 24,
                  width: '100%',
                  maxWidth: "700px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)', // Updated box shadow
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Questionnaire
                    accessToken={accessToken}
                    user={user}
                  />
                </Paper>
              ) : (
                <Paper style={{
                  padding: 24,
                  width: '100%',
                  maxWidth: "600px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)', // Updated box shadow
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <UploadFilesWelcome files={files} setFiles={setFiles} is_multiple={false} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                    <Button
                      variant="contained"
                      onClick={handleUpload}
                      disabled={isFilesEmpty()}
                      style={{
                        backgroundColor: isFilesEmpty() ? '#CCCCCC' : '#6BBDBD', // Gray when disabled, teal when enabled
                        color: 'white',
                        width: '120px',
                        fontWeight: 'bold',
                        fontSize: "14px",
                        textTransform: 'none',
                        borderRadius: '20px',
                        boxShadow: 'none',
                        marginTop: '20px',
                        '&:hover': {
                          backgroundColor: isFilesEmpty() ? '#CCCCCC' : '#6BBDBD', // Maintain color on hover
                        },
                      }}
                    >
                      ¡Continuar!
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 'auto', marginTop: "-20px" }}>
                    <Typography onClick={handleUploadSkip} style={{ cursor: 'pointer', color: "gray", display: 'flex', alignItems: 'center', textDecoration: 'underline', fontFamily: "Inter", fontSize: "14px", fontWeight: "400" }}>
                      Seguir sin subir un documento
                    </Typography>
                  </Box>
                </Paper>
              )}

              {/* Add the Senja widget div below the Paper component */}
              {/* <Box sx={{ marginTop: "60px", width: "300px", height: "100px" }}>
                <div className="senja-embed" data-id="3bd958a6-7beb-4d50-acc4-6e5730b43bd9" data-mode="shadow" data-lazyload="false" style={{ display: 'block' }}></div>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.root}>
          {/* Left side - How Typed works */}
          <Grid item xs={12} md={3}>
            <Box className={classes.leftColumn}>
              <Box sx={{ textAlign: 'center', marginBottom: '3rem' }}>
                <Typography variant="h4" className={classes.title}>
                  ¿Cómo funciona Typed?
                </Typography>
              </Box>
              <Box className={classes.stepsContainer}>
                {steps.map((step, index) => (
                  <Box key={index} className={classes.stepContainer} sx={{ textAlign: 'center' }}>
                    <Box
                      sx={{
                        bgcolor: step.color,
                        borderRadius: '50%',
                        p: 1,
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        mb: 2,
                      }}
                    >
                      {step.icon}
                    </Box>
                    <Typography variant="h6" className={classes.stepTitle}>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" className={classes.stepDescription}>
                      {step.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Right side - Upload document */}
          <Grid item xs={12} md={9} style={{
            overflowY: 'auto',
            height: '100vh',
          }}>
            <Box p={2} bgcolor="#F8FFFF" height="100%" display="flex" flexDirection="column" alignItems="center">


              {/* Upload Paper or Questionnaire */}
              <Box sx={{ textAlign: 'center', mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "60px" }}>
                <Typography variant="h5" className={classes.uploadTitle}>
                  Sube tu primer documento
                </Typography>
                <Typography variant="h5" style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", color: "#666666", marginTop: "10px", width: "80%" }}>
                  Genera preguntas test y resúmenes completos de cualquier documento con nuestra IA
                </Typography>
              </Box>

              {showQuestionnaire ? (
                <Paper style={{
                  padding: 24,
                  width: '100%',
                  maxWidth: "700px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)', // Updated box shadow
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Questionnaire
                    accessToken={accessToken}
                    user={user}
                  />
                </Paper>
              ) : (
                <Paper style={{
                  padding: 24,
                  width: '100%',
                  maxWidth: "600px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)', // Updated box shadow
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <UploadFilesWelcome files={files} setFiles={setFiles} is_multiple={false} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                    <Button
                      variant="contained"
                      onClick={handleUpload}
                      disabled={isFilesEmpty()}
                      style={{
                        backgroundColor: isFilesEmpty() ? '#CCCCCC' : '#6BBDBD', // Gray when disabled, teal when enabled
                        color: 'white',
                        width: '120px',
                        fontWeight: 'bold',
                        fontSize: "14px",
                        textTransform: 'none',
                        borderRadius: '20px',
                        boxShadow: 'none',
                        marginTop: '20px',
                        '&:hover': {
                          backgroundColor: isFilesEmpty() ? '#CCCCCC' : '#6BBDBD', // Maintain color on hover
                        },
                      }}
                    >
                      ¡Continuar!
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 'auto', marginTop: "-20px" }}>
                    <Typography onClick={handleUploadSkip} style={{ cursor: 'pointer', color: "gray", display: 'flex', alignItems: 'center', textDecoration: 'underline', fontFamily: "Inter", fontSize: "14px", fontWeight: "400" }}>
                      Seguir sin subir un documento
                    </Typography>
                  </Box>
                </Paper>
              )}

              {/* Add the Senja widget div below the Paper component */}
              <Box sx={{ marginTop: "60px", width: "750px", height: "100px" }}>
                <div className="senja-embed" data-id="3bd958a6-7beb-4d50-acc4-6e5730b43bd9" data-mode="shadow" data-lazyload="false" style={{ display: 'block' }}></div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NewOnboarding;
