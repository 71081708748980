import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  LinearProgress,
  Paper,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Flashcards from './Flashcards';
import QuestionFlashcard from './QuestionFlashcards';
import { motion, AnimatePresence } from 'framer-motion';
import FlashcardSummary from './FlashcardSummary';
import { useLocation } from 'react-router-dom';
import { updateFlashcardAppearances, updateSetFlashcardCompletion, deleteFlashcard } from '../Api/FlashcardsApi';
import { useAuth } from '../Auth/Authhandle';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExplicacionFlashcard from './ExplicacionFlashcard';
import { streamJustification } from '../Exam/QdrantJustification';

import Chip from '@mui/material/Chip';
import ReplayIcon from '@mui/icons-material/Replay';
import FlagIcon from '@mui/icons-material/Flag';

const FlashcardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "800px",
  margin: '0 auto',
  padding: theme.spacing(2),
}));

const QuestionBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: "20px",
  backgroundColor: "white",
  borderRadius: "20px",
  minHeight: "200px",
  width: "100%",
  maxWidth: "800px",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: 'white',
  color: '#6BBDBD',
  border: '1px solid #6BBDBD',
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.1)',
  },
  '&.primary': {
    backgroundColor: '#6BBDBD',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5AACAC',
    },
  }
}));

const ConfettiPiece = styled(motion.div)({
  position: 'absolute',
  width: '10px',
  height: '10px',
  borderRadius: '50%',
});

const HintButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 500,
  backgroundColor: 'transparent',
  color: '#666666',
  border: 'none',
  '&:hover': {
    backgroundColor: 'rgba(102, 102, 102, 0.08)',
  },
  '&.Mui-disabled': {
    color: 'rgba(102, 102, 102, 0.5)',
  }
}));

const calculateDifficulty = (card) => {
  if (card.usedReveal) {
    return 'muy_dificil';
  }

  if (card.answeredCorrectly?.length !== card.answers.length) {
    return 'sin_responder';
  }

  switch (card.appearances) {
    case 1:
      return 'facil';
    case 2:
      return 'normal';
    case 3:
      return 'dificil';
    case 4:
    default:
      return 'muy_dificil';
  }
};

// Add this constant before the FlashcardPage component

const SuccessAnimation = () => {
  const colors = ['#00B564', '#6BBDBD', '#E2F1F1', '#FFD700'];
  const confettiPieces = Array.from({ length: 50 });

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      {confettiPieces.map((_, i) => (
        <ConfettiPiece
          key={i}
          initial={{
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            scale: 0,
          }}
          animate={{
            x: window.innerWidth * Math.random(),
            y: window.innerHeight * Math.random(),
            scale: Math.random() * 2 + 1,
            opacity: 0,
          }}
          transition={{
            duration: 1.5,
            ease: "easeOut",
            delay: Math.random() * 0.2,
          }}
          style={{
            backgroundColor: colors[Math.floor(Math.random() * colors.length)],
          }}
        />
      ))}
    </Box>
  );
};

// Define the ExplanationButton styled component
const ExplanationButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: '#F8F9FA',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#EAECEF',
  },
}));

// Add this styled component after the other styled components
const FinishButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: 'white',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#F8F9FA',
    borderColor: '#666666',
  },
}));

// Add these color constants at the top of the file
const difficultyColors = {
  muy_dificil: { bg: '#FFEBEE', text: '#F44336', hover: '#FFCDD2' },
  dificil: { bg: '#FFF3E0', text: '#FF9800', hover: '#FFE0B2' },
  normal: { bg: '#E3F2FD', text: '#2196F3', hover: '#BBDEFB' },
  facil: { bg: '#E8F5E9', text: '#4CAF50', hover: '#C8E6C9' }
};

// Add this styled component with the other styled components
const RepeatChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#FFEBEE',
  color: 'red',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '12px',
  fontWeight: 500,
  height: '30px',
  padding: '0px 10px',
  '& .MuiChip-icon': {
    color: 'red',
    fontSize: '16px'
  }
}));

const FlagDialog = ({ open, onClose, onFlag }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '400px',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{
        fontFamily: "'Fira Sans', sans-serif",
        fontSize: '20px',
        fontWeight: 500,
        color: '#2D2D2D',
        pb: 1
      }}>
        Eliminar flashcard
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: '#666666',
          fontFamily: "'Fira Sans', sans-serif",
          fontSize: '14px'
        }}>
          ¿Quieres eliminar esta tarjeta y que no aparezca en tus flashcards?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#666666',
            textTransform: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'rgba(102, 102, 102, 0.08)',
            },
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onFlag}
          variant="contained"
          sx={{
            backgroundColor: '#FF4444',
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '20px',
            padding: '6px 16px',
            '&:hover': {
              backgroundColor: '#FF4444',
            },
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add this styled component with the other styled components
const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4444',
  color: 'white',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '20px',
  padding: '6px 16px',
  '&:hover': {
    backgroundColor: '#E03131',  // Slightly darker red on hover
  },
}));

const FlashcardPage = () => {
  const location = useLocation();
  const studyMode = location.state?.studyMode || 'typing';
  const receivedFlashcards = location.state?.flashcardsSet?.flashcards || [];
  const documentTitle = location.state?.customization?.documentTitle || 'Untitled Document';
  const folderColor = location.state?.customization?.folderColor || '#6BBDBD';
  const [initialCardCount, setInitialCardCount] = useState(receivedFlashcards.length);
  let flashcards = receivedFlashcards || [];
  // Transform received flashcards to match the expected format
  const transformFlashcards = (flashcardsData) => {
    return flashcardsData.map(card => ({
      id: card.guid,
      type: card.type,
      question: card.question,
      answer: card.answer,
      answers: card.answers || [],
      text: card.text,
      currentClozeIndex: 0,
      attempts: 0,
      appearances: 1,
      answeredCorrectly: [],
      revealed: false,
      difficulty: 'sin_responder'
    }));
  };

  // Replace the hardcoded flashcards array with transformed received flashcards
  const [cards, setCards] = useState(transformFlashcards(receivedFlashcards));

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [state, setState] = useState({
    isCorrect: false,
    isAttempted: false,
    userInput: '',
    wrongAnswers: []
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [startTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [showDifficultyOptions, setShowDifficultyOptions] = useState(false);
  const { accessToken, user } = useAuth();
  const currentCard = cards[currentCardIndex];
  const currentAnswer = currentCard.answers[currentCard.currentClozeIndex];
  const progress = ((currentCardIndex + 1) / cards.length) * 100;
  const [isHandleFinish, setIsHandleFinish] = useState(false);
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);
  const [explanationText, setExplanationText] = useState("");
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);
  const [isFlagDialogOpen, setIsFlagDialogOpen] = useState(false);

  const checkAnswer = () => {
    if (!state.userInput.trim()) return;

    const correct = state.userInput.trim().toLowerCase() === currentAnswer.toLowerCase();

    if (correct) {
      setShowSuccess(true);

      // Update cards state immediately
      setCards(prevCards => {
        const newCards = [...prevCards];
        const currentAnsweredCorrectly = [...(newCards[currentCardIndex].answeredCorrectly || [])];
        currentAnsweredCorrectly.push(currentCard.currentClozeIndex);

        const updatedCard = {
          ...newCards[currentCardIndex],
          answeredCorrectly: currentAnsweredCorrectly,
          currentClozeIndex: currentCard.currentClozeIndex === currentCard.answers.length - 1
            ? currentCard.currentClozeIndex
            : newCards[currentCardIndex].currentClozeIndex + 1
        };

        if (currentCard.currentClozeIndex === currentCard.answers.length - 1) {
          updatedCard.difficulty = calculateDifficulty(updatedCard);
        }

        newCards[currentCardIndex] = updatedCard;
        return newCards;
      });

      // Add delay before moving to next question
      setTimeout(async () => {
        setShowSuccess(false);

        if (currentCard.currentClozeIndex === currentCard.answers.length - 1) {
          setState(prev => ({
            ...prev,
            isCorrect: true,
            isAttempted: true,
          }));

          // Move to next question
          if (currentCardIndex < cards.length - 1) {
            setCurrentCardIndex(prev => prev + 1);
            setState({
              isCorrect: false,
              isAttempted: false,
              userInput: '',
              wrongAnswers: []
            });
          } else {
            setEndTime(Date.now());
            setIsCompleted(true);
            await updateBackendOnCompletion();
          }
        } else {
          setState({
            isCorrect: false,
            isAttempted: false,
            userInput: '',
            wrongAnswers: []
          });
        }
      }, 2000); // 3 second delay

    } else {
      setCards(prevCards => {
        const newCards = [...prevCards];
        const updatedCard = {
          ...newCards[currentCardIndex],
          attempts: newCards[currentCardIndex].attempts + 1
        };

        if (updatedCard.attempts > 4 && studyMode === 'typing' && !updatedCard.scheduledForRepetition) {
          // Clone the card to move to the end
          const cardToMoveToEnd = {
            ...newCards[currentCardIndex],
            attempts: 0,
            currentClozeIndex: 0,
            answeredCorrectly: [],
            revealed: false,
            appearances: (newCards[currentCardIndex].appearances || 1) + 1
          };
          // Add the cloned card to the end
          newCards.push(cardToMoveToEnd);
          // Mark current card as scheduled for repetition
          updatedCard.scheduledForRepetition = true;
        }

        newCards[currentCardIndex] = updatedCard;
        return newCards;
      });

      setState(prev => ({
        ...prev,
        isCorrect: false,
        isAttempted: true,
        userInput: '',
        wrongAnswers: [...new Set([...prev.wrongAnswers, prev.userInput.trim()])]
      }));
    }
  };

  const handleNext = async () => {

    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(prev => prev + 1);
      setState({
        isCorrect: false,
        isAttempted: false,
        userInput: '',
        wrongAnswers: []
      });
      setCards(prevCards => {
        const newCards = [...prevCards];
        newCards[currentCardIndex] = {
          ...newCards[currentCardIndex],
          revealed: false,
          currentClozeIndex: 0
        };
        return newCards;
      });
    } else {
      setEndTime(Date.now());
      setIsCompleted(true);
      await updateBackendOnCompletion();
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(prev => prev - 1);
      setState({
        isCorrect: false,
        isAttempted: false,
        userInput: ''
      });
    }
  };

  const handleShowHint = () => {
    const hint = currentCard.answers[currentCard.currentClozeIndex][0] + '...';
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[currentCardIndex] = {
        ...newCards[currentCardIndex],
        usedHint: true,
        difficulty: calculateDifficulty({
          ...newCards[currentCardIndex],
          usedHint: true
        })
      };
      return newCards;
    });
    setState(prev => ({
      ...prev,
      userInput: hint,
      isAttempted: false
    }));
  };

  const handleShowAnswer = () => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      const currentCard = newCards[currentCardIndex];
      const currentAnsweredCorrectly = currentCard.answeredCorrectly || [];

      // Only add to end if in typing mode and not already scheduled for repetition
      if (studyMode === 'typing' && !currentCard.scheduledForRepetition) {
        // Clone the card to move to the end
        const cardToMoveToEnd = {
          ...currentCard,
          attempts: 0,
          currentClozeIndex: 0,
          answeredCorrectly: [],
          revealed: false,
          appearances: (currentCard.appearances || 1) + 1
        };
        // Add the cloned card to the end
        newCards.push(cardToMoveToEnd);
      }

      // Update the current card
      newCards[currentCardIndex] = {
        ...currentCard,
        revealed: true,
        currentClozeIndex: currentCard.answers.length - 1,
        attempts: 5,
        answeredCorrectly: currentAnsweredCorrectly,
        usedReveal: true,
        difficulty: 'dificil',
        scheduledForRepetition: studyMode === 'typing' // Only set as scheduled if in typing mode
      };

      return newCards;
    });

    setState(prev => ({
      ...prev,
      isAttempted: true,
      userInput: '',
      isCorrect: false
    }));
    setShowDifficultyOptions(true);
  };

  const handleSelectDifficulty = async (difficulty) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      const currentCard = newCards[currentCardIndex];

      if (difficulty === 'dificil') {
        // Clone the card to move to the end
        const cardToMoveToEnd = {
          ...currentCard,
          attempts: 0,
          currentClozeIndex: 0,
          answeredCorrectly: [],
          revealed: false,
          appearances: (currentCard.appearances || 1) + 1
        };
        newCards.push(cardToMoveToEnd);
      }

      // Update current card
      newCards[currentCardIndex] = {
        ...currentCard,
        selectedDifficulty: difficulty,
        difficulty: difficulty,
        scheduledForRepetition: difficulty === 'dificil',
        answeredCorrectly: difficulty === 'facil'
          ? Array.from({ length: currentCard.answers.length }, (_, i) => i)
          : currentCard.answeredCorrectly || []
      };

      // Handle the navigation here, with access to newCards
      if (currentCardIndex < newCards.length - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      } else {
        setEndTime(Date.now());
        setIsCompleted(true);
        updateBackendOnCompletion();
      }

      return newCards;
    });
    setShowDifficultyOptions(false);
  };

  // Update the keyboard handler
  React.useEffect(() => {
    const handleKeyDown = async (e) => {
      if (currentCard.revealed || state.isCorrect) {
        if (currentCardIndex < cards.length - 1) {
          setCurrentCardIndex(prev => prev + 1);
          setState({
            isCorrect: false,
            isAttempted: false,
            userInput: '',
            wrongAnswers: []
          });
          setCards(prevCards => {
            const newCards = [...prevCards];
            newCards[currentCardIndex] = {
              ...newCards[currentCardIndex],
              revealed: false
            };
            return newCards;
          });
        } else {
          // Handle last card the same way as "Continuar" button
          setEndTime(Date.now());
          setIsCompleted(true);
          await updateBackendOnCompletion();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentCard.revealed, state.isCorrect, currentCardIndex, cards.length]);

  // Add explain function
  const handleExplain = async () => {
    setIsExplanationOpen(true);
    setIsLoadingExplanation(true);
    setExplanationText("");
    const document_guid = location.state?.flashcardsSet?.set_flashcard?.document;


    try {
      await streamJustification(
        {
          answer: currentCard.answer,
          question: currentCard.question,
          text: currentCard.text,
          type: currentCard.type,
          documentId: document_guid,
          userId: user['sub'],
          flashcardId: currentCard.id,
          typeJustification: "flashcard",
        },
        (word) => {
          setExplanationText(prev => prev + word);
        },
        user,
        accessToken
      );

    } catch (error) {
      console.error("Error fetching explanation:", error);
      setExplanationText("Lo sentimos, no pudimos generar la explicación en este momento.");
    } finally {
      setIsLoadingExplanation(false);
    }
  };

  // Add a utility function to get statistics
  const getFlashcardStats = () => {
    const stats = {
      totalCards: cards.length,
      averageAttempts: cards.reduce((acc, card) => acc + card.attempts, 0) / cards.length,
      timeSpent: endTime ? Math.floor((endTime - startTime) / 1000) : 0,
      difficulty: {
        facil: 0,
        normal: 0,
        dificil: 0,
        muy_dificil: 0,
        sin_responder: 0
      }
    };

    cards.forEach(card => {
      const difficulty = studyMode === 'flashcard' ?
        card.selectedDifficulty || 'sin_responder' :
        calculateDifficulty(card);

      if (difficulty in stats.difficulty) {
        stats.difficulty[difficulty]++;
      }
    });

    stats.masteryGrade = ((stats.difficulty.facil + stats.difficulty.normal) / stats.totalCards) * 100;

    return stats;
  };

  const renderFlashcard = () => {
    if (currentCard.type === 'question') {
      return <QuestionFlashcard currentCard={currentCard} state={state} studyMode={studyMode} />;
    }
    return <Flashcards currentCard={currentCard} state={state} studyMode={studyMode} />;
  };

  const handleRestart = () => {
    setIsCompleted(false);
    setCurrentCardIndex(0);
    setCards(flashcards.map(card => ({
      ...card,
      currentClozeIndex: 0,
      attempts: 0,
      answeredCorrectly: [],
      revealed: false,
      difficulty: 'sin_responder'
    })));
    setState({
      isCorrect: false,
      isAttempted: false,
      userInput: '',
      wrongAnswers: []
    });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleFinish = async () => {
    setIsHandleFinish(true);
    setEndTime(Date.now());
    setIsCompleted(true);
    setOpenConfirmDialog(false);
    await updateBackendOnFinalizar();
  };

  const getUniqueCardsWithFinalStateOnFinalClick = (cards) => {
    // Create a map to store the latest state of each unique card
    const uniqueCardsMap = cards.reduce((acc, card) => {
      // If this card ID exists in the map
      if (acc.has(card.id)) {
        const existingCard = acc.get(card.id);
        // Update only if this instance is more recent (higher index in array)
        const cardIndex = cards.indexOf(card);
        const existingIndex = cards.indexOf(existingCard);

        if (cardIndex > existingIndex) {
          acc.set(card.id, card);
        }
      } else {
        // If this is the first instance of this card ID, add it
        acc.set(card.id, card);
      }
      return acc;
    }, new Map());

    // Convert map back to array and update appearances
    return Array.from(uniqueCardsMap.values()).map(card => ({
      ...card,
      // Set appearances to 5 if no correct answers, otherwise keep original count
      appearances: (!card.answeredCorrectly || card.answeredCorrectly.length === 0) ? 5 : (card.appearances || 1)
    }));
  };

  const getUniqueCardsWithFinalState = (cards) => {
    // Create a map to store the latest state of each unique card

    const uniqueCardsMap = cards.reduce((acc, card) => {
      // If this card ID exists, update it only if this instance has more appearances
      if (acc.has(card.id)) {
        const existingCard = acc.get(card.id);
        if ((card.appearances || 1) > (existingCard.appearances || 1)) {
          acc.set(card.id, card);
        }
      } else {
        // If this is the first instance of this card ID, add it
        acc.set(card.id, card);
      }
      return acc;
    }, new Map());

    // Convert map back to array
    return Array.from(uniqueCardsMap.values());
  };

  const updateBackendOnCompletion = async () => {
    const timeSpent = Math.floor((endTime - startTime) / 1000);
    const setFlashcardId = location.state?.flashcardsSet?.set_flashcard?.guid;
    const documentId = location.state?.flashcardsSet?.set_flashcard?.document;

    // Create appearances update object
    const flashcardUpdates = cards.reduce((acc, card) => {
      acc[card.id] = card.appearances;

      return acc;
    }, {});

    // Update flashcards appearances
    if (Object.keys(flashcardUpdates).length > 0) {
      try {
        await updateFlashcardAppearances(accessToken, documentId, flashcardUpdates, user);
      } catch (error) {
        console.error('Error updating flashcard appearances:', error);
      }
    }

    //Update set flashcard completion status and time
    if (setFlashcardId) {
      try {
        await updateSetFlashcardCompletion(accessToken, setFlashcardId, timeSpent, user);
      } catch (error) {
        console.error('Error updating set flashcard:', error);
      }
    }
  };

  const updateBackendOnFinalizar = async () => {
    const timeSpent = Math.floor((endTime - startTime) / 1000);
    const setFlashcardId = location.state?.flashcardsSet?.set_flashcard?.guid;
    const documentId = location.state?.flashcardsSet?.set_flashcard?.document;

    const flashcardUpdates = cards.reduce((acc, card) => {
      if (studyMode === "typing") {
        // If card hasn't been attempted at all, set to 4 attempts (most difficult)
        if (card.attempts === 0 && card.answeredCorrectly?.length === 0) {
          acc[card.id] = 4;
        } else {
          acc[card.id] = card.attempts === 0 ? 1 :
            card.attempts <= 2 ? 2 :
              card.attempts <= 4 ? 3 : 4;
        }
      } else {
        // For flashcard mode, set appearances based on difficulty
        acc[card.id] = card.difficulty === 'dificil' ? 3 : 1;
      }
      return acc;
    }, {});

    // Update flashcards appearances
    if (Object.keys(flashcardUpdates).length > 0) {
      try {
        await updateFlashcardAppearances(accessToken, documentId, flashcardUpdates, user);
      } catch (error) {
        console.error('Error updating flashcard appearances:', error);
      }
    }

    // Update set flashcard completion status and time
    if (setFlashcardId) {
      try {
        await updateSetFlashcardCompletion(accessToken, setFlashcardId, timeSpent, user);
      } catch (error) {
        console.error('Error updating set flashcard:', error);
      }
    }
  };

  const handleFlagClick = () => {
    setIsFlagDialogOpen(true);
  };

  const handleFlagConfirm = async () => {
    try {
      await deleteFlashcard(accessToken, currentCard.id, user);
      console.log("currentCard", currentCard);
      // Update local state to remove the card
      setCards(prevCards => {
        const newCards = prevCards.filter(card => card.id !== currentCard.id);
        return newCards;
      });

      // Update initialCardCount when a card is deleted
      setInitialCardCount(prev => prev - 1);

      // Move to next card if available
      if (currentCardIndex < cards.length - 1) {
        setCurrentCardIndex(prev => prev + 1);
      } else if (currentCardIndex > 0) {
        setCurrentCardIndex(prev => prev - 1);
      }

      setIsFlagDialogOpen(false);
    } catch (error) {
      console.error('Error deleting flashcard:', error);
      // Optionally add error handling UI feedback here
    }
  };

  return (
    <FlashcardContainer>
      {isCompleted ? (
        <FlashcardSummary
          onRestart={handleRestart}
          stats={getFlashcardStats()}
          cards={isHandleFinish ?
            getUniqueCardsWithFinalStateOnFinalClick(cards) :
            getUniqueCardsWithFinalState(cards)}
          studyMode={studyMode}
          user={user}
          accessToken={accessToken}
        />
      ) : (
        <>
          <AnimatePresence>
            {showSuccess && <SuccessAnimation />}
          </AnimatePresence>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 6
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}>
              <Box sx={{
                width: '24px',
                height: '24px',
                backgroundColor: folderColor,
                borderRadius: '6px'
              }} />
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontWeight: 600,
                fontSize: '24px',
                color: '#2D2D2D'
              }}>
                {documentTitle}
              </Typography>
            </Box>

            <FinishButton onClick={handleOpenConfirmDialog}>
              Finalizar
            </FinishButton>
          </Box>

          <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1
            }}>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                fontWeight: 600,
                color: '#6BBDBD'
              }}>
                {studyMode === 'flashcard' ?
                  cards.filter(card =>
                    card.selectedDifficulty === 'facil'
                  ).length
                  :
                  cards.filter(card =>
                    card.answeredCorrectly?.length === card.answers.length &&
                    card.attempts <= 4 &&
                    !card.usedReveal
                  ).length
                }
              </Typography>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                color: '#666666'
              }}>
                de
              </Typography>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                fontWeight: 600,
                color: '#6BBDBD'
              }}>
                {initialCardCount}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '18px',
                  color: '#666666'
                }}>
                  tarjetas completadas
                </Typography>
                <Tooltip
                  title="Tarjeta resuelta en menos de 5 intentos"
                  placement="top"
                  arrow
                >
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: 16,
                      color: '#666666',
                      cursor: 'help'
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <motion.div
            animate={{
              scale: showSuccess ? 1.02 : 1
            }}
            transition={{
              duration: 0.3
            }}
          >

            {currentCard.appearances > 1 && (
              <Box sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}>
                <RepeatChip
                  icon={<ReplayIcon />}
                  label="Error anterior"
                />
              </Box>
            )}

            {/* <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Typography sx={{
                color: '#666666',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px'
              }}>
                Intentos: {currentCard.attempts}
              </Typography>
              <Typography sx={{
                color: '#666666',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px'
              }}>
                Apariciones: {currentCard.appearances || 1}
              </Typography>
            </Box> */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 2
            }}>
              <DeleteButton
                onClick={handleFlagClick}
                size="small"
              >
                Reportar
              </DeleteButton>
            </Box>

            <QuestionBox>

              {renderFlashcard()}
            </QuestionBox>

          </motion.div>

          <Box sx={{
            width: '100%',
            maxWidth: '650px',
            margin: '0 auto',
            marginTop: '40px'
          }}>
            {studyMode === 'typing' && (
              <Box sx={{ position: 'relative', mb: 3 }}>
                {state.wrongAnswers.length > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-20px',
                      right: '12px',
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'row-reverse'
                    }}
                  >
                    {state.wrongAnswers.map((answer, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: '#FF4444',
                          fontSize: '14px',
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: 500
                        }}
                      >
                        {answer}
                      </Typography>
                    ))}
                  </Box>
                )}
                <TextField
                  fullWidth
                  autoFocus
                  value={state.userInput}
                  onChange={(e) => setState(prev => ({
                    ...prev,
                    userInput: e.target.value
                  }))}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !state.isCorrect) {
                      checkAnswer();
                    }
                  }}
                  placeholder="Escribe tu respuesta..."
                  disabled={state.isCorrect}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: 'white',
                      '& fieldset': {
                        borderColor: state.isAttempted && !state.isCorrect ? '#FF4444' : '#E2F1F1',
                      },
                      '&:hover fieldset': {
                        borderColor: state.isAttempted && !state.isCorrect ? '#FF4444' : '#6BBDBD',
                      },
                    }
                  }}
                />
                {!state.isCorrect && (
                  <Box sx={{
                    position: 'absolute',
                    right: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    color: '#666'
                  }}>
                    <KeyboardReturnIcon sx={{ fontSize: 20 }} />
                    <Typography variant="caption">Enter</Typography>
                  </Box>
                )}
              </Box>
            )}

            {studyMode === 'flashcard' && !state.isCorrect && !currentCard.revealed && (
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography sx={{
                  color: '#666666',
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px'
                }}>
                  Di la respuesta en voz alta y comprueba si es correcta
                </Typography>
              </Box>
            )}



            {(!state.isCorrect && !currentCard.revealed) ? (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: 4
              }}>
                <HintButton
                  onClick={handleShowHint}
                  disabled={state.isCorrect}
                >
                  Pista
                </HintButton>
                <ActionButton
                  onClick={handleShowAnswer}
                  className="primary"
                  disabled={state.isCorrect}
                >
                  Ver respuesta
                </ActionButton>
              </Box>
            ) : (
              <>
                {(!showDifficultyOptions || studyMode !== 'flashcard') && (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    mt: 4
                  }}>
                    <Typography
                      sx={{
                        color: '#666',
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '14px'
                      }}
                    >
                      Pulsa cualquier tecla para continuar
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2
                    }}>
                      <ExplanationButton
                        onClick={handleExplain}
                      >
                        Explícame
                      </ExplanationButton>
                      <ActionButton
                        onClick={handleNext}
                        className="primary"
                      >
                        Continuar
                      </ActionButton>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Dialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            PaperProps={{
              sx: {
                borderRadius: '12px',
                maxWidth: '400px',
                padding: '8px'
              }
            }}
          >
            <DialogTitle sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '20px',
              fontWeight: 500,
              color: '#2D2D2D',
              pb: 1
            }}>
              ¿Finalizar sesión?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{
                color: '#666666',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px'
              }}>
                ¿Estás seguro de que quieres finalizar la sesión de estudio? Tu progreso se guardará hasta este punto.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
              <Button
                onClick={() => setOpenConfirmDialog(false)}
                sx={{
                  color: '#666666',
                  textTransform: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: 'rgba(102, 102, 102, 0.08)',
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleFinish}
                variant="contained"
                sx={{
                  backgroundColor: '#6BBDBD',
                  color: 'white',
                  textTransform: 'none',
                  boxShadow: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  borderRadius: '20px',
                  padding: '6px 16px',
                  '&:hover': {
                    backgroundColor: '#5AACAC',
                  },
                }}
              >
                Finalizar
              </Button>
            </DialogActions>
          </Dialog>

          {showDifficultyOptions && studyMode === 'flashcard' && (
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
              {[
                { level: 'dificil', label: 'Difícil' },
                { level: 'facil', label: 'Fácil' }
              ].map(({ level, label }) => (
                <Button
                  key={level}
                  onClick={() => handleSelectDifficulty(level)}
                  sx={{
                    borderRadius: '20px',
                    padding: '12px 24px',
                    textTransform: 'none',
                    fontFamily: "'Fira Sans', sans-serif",
                    fontSize: '16px',
                    fontWeight: 600,
                    backgroundColor: difficultyColors[level].bg,
                    color: difficultyColors[level].text,
                    border: `2px solid transparent`,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: difficultyColors[level].hover,
                      borderColor: difficultyColors[level].text,
                      transform: 'translateY(-2px)',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`
                    },
                    '&:active': {
                      transform: 'translateY(0)',
                      boxShadow: 'none'
                    }
                  }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          )}

          <ExplicacionFlashcard
            open={isExplanationOpen}
            onClose={() => setIsExplanationOpen(false)}
            question={currentCard.question}
            correctAnswer={currentCard.answers[currentCard.currentClozeIndex]}
            userAnswer={state.userInput}
            explanationText={explanationText}
            isLoading={isLoadingExplanation}
          />

          <FlagDialog
            open={isFlagDialogOpen}
            onClose={() => setIsFlagDialogOpen(false)}
            onFlag={handleFlagConfirm}
          />
        </>
      )}
    </FlashcardContainer>
  );
};

export default FlashcardPage;
